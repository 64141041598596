import type { ISnapshot } from '@/types/store'
import type { Ref } from 'vue'
import { toast } from 'vue3-toastify'

export class ErrorInvalid extends Error {
  error: string

  constructor(message: string, data: string) {
    super(message)
    this.name = 'ErrorInvalid'
    this.error = data
  }

  getErrorData() {
    return this.error
  }
}

export class ErrorUnauthorized extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'ErrorUnauthorized'
  }
}

export class ErrorDoDoS extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'ErrorDoDoS'
  }
}

export interface Request<T> {
  input: string
  method: string
  body?: T
  formData?: FormData
}

export async function useLoadingApi(
  state: Ref<ISnapshot<any>>,
  request: Request<any>,
  options = {}
) {
  try {
    console.info('[options] options loading api', options)
    state.value.isLoading = true
    return await useApi(request, options)
  } finally {
    /* empty */
  }
}

export async function useApi(request: Request<any>, options: { headers?: {} } = {}) {
  const requestOptions: RequestInit = {
    method: request.method,
    cache: 'no-cache' as RequestCache,
    credentials: 'include' as RequestCredentials,
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'follow' as RequestRedirect,
    referrerPolicy: 'no-referrer' as ReferrerPolicy,
    body: undefined
  }

  console.info('[options] options', options)
  if (options.headers) {
    requestOptions.headers = options.headers
  }

  if (request.body) {
    requestOptions.body = JSON.stringify(request.body)
  } else if (request.formData) {
    requestOptions.body = request.formData
  }

  const response = await fetch(
    import.meta.env.VITE_APP_PORTAL_BASE_URL + request.input,
    requestOptions
  )
  switch (response.status) {
    case 200:
      return await response.json()
    case 201:
      return await response.json()
    case 400:
      // eslint-disable-next-line no-case-declarations
      const ed = await response.json()
      throw new ErrorInvalid(ed.message ?? 'action failed', ed)
    case 401:
      toast.error('Authentication Failed')
      throw new ErrorUnauthorized('user not authorized')
    case 429:
      throw new ErrorDoDoS('Too may request, please try again after some time.')
    default:
      throw new Error('Please try again, action failed')
  }
}

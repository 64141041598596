import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useLoadingApi } from '@/utils/api'
import type { ISnapshot } from '@/types/store'
import { useTopLoadingStore } from '@/stores/loadingStore'
import posthog from 'posthog-js'
import router from '@/router'

export const userStore = defineStore('userStore', () => {
  const snapshot = ref<ISnapshot<any>>({
    isLoading: false,
    data: {},
    metricData: {}
  })
  const status = computed(() => {
    return snapshot.value.data.status || undefined
  })

  async function fetchUser() {
    const currentUser = await useLoadingApi(snapshot, {
      method: 'GET',
      input: '/publisher/account/user'
    })
    snapshot.value.data = currentUser
    return currentUser
  }

  async function createMetrics(payload: any) {
    const currentUser = await useLoadingApi(snapshot, {
      method: 'PATCH',
      input: '/publisher/account/user',
      body: payload
    })
    snapshot.value.data = currentUser
    return currentUser
  }

  async function updateMetrics(payload: any, id: any) {
    const currentUser = await useLoadingApi(snapshot, {
      method: 'PATCH',
      input: '/publisher/metrics/' + id,
      body: payload
    })
    snapshot.value.metricData = currentUser
    return currentUser
  }

  async function deleteMetrics(id: any) {
    const currentUser = await useLoadingApi(snapshot, {
      method: 'DELETE',
      input: '/publisher/metrics/' + id
    })
    snapshot.value.metricData = currentUser
    return currentUser
  }

  async function updateUser(payload: any) {
    return await useLoadingApi(snapshot, {
      method: 'PATCH',
      input: '/publisher/account/user',
      body: payload
    })
  }

  async function authorizePassCode(authorizationPayload = {}) {
    const data = await useLoadingApi(snapshot, {
      method: 'POST',
      input: '/publisher/account/verify-email',
      body: authorizationPayload
    })
    snapshot.value.data = data
  }

  async function resendPasscode(resendPassCodePayload: {}) {
    await useLoadingApi(snapshot, {
      method: 'PATCH',
      input: '/publisher/account/resend-otp',
      body: resendPassCodePayload
    })
  }

  async function accountSetup(accountInfoPayload: {}) {
    const data = await useLoadingApi(snapshot, {
      method: 'PATCH',
      input: '/publisher/account/setup',
      body: accountInfoPayload
    })
    snapshot.value.data = data
  }

  async function uploadDocuments(formData: any) {
    try {
      const response = await fetch(
        import.meta.env.VITE_APP_PORTAL_BASE_URL + '/publisher/documents',
        {
          method: 'POST',
          body: formData,
          credentials: 'include'
        }
      )

      if (!response.ok) {
        throw new Error('Failed to upload documents')
      }

      const data = await response.json()
      snapshot.value.isLoading = false
      return data
    } catch (error: any) {
      throw new Error(error.message)
    }
  }

  async function logout() {
    snapshot.value.isLoading = false
    snapshot.value.data = {}
    await router.push('/')
    await useLoadingApi(snapshot, {
      method: 'GET',
      input: '/publisher/account/logout'
    })

    useTopLoadingStore().finishLoading()
    window.location.reload()
  }

  return {
    snapshot,
    fetchUser,
    createMetrics,
    updateMetrics,
    deleteMetrics,
    authorizePassCode,
    resendPasscode,
    accountSetup,
    updateUser,
    uploadDocuments,
    status,

    logout
  }
})

import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { userStore } from '@/stores/userStore'
import { useTopLoadingStore } from '@/stores/loadingStore'

export async function hasActiveSessionGaurd(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const user = userStore()
  if (user.status === undefined) {
    user.snapshot.data = {}
    next()
  } else {
    next({ name: 'App' })
  }
}

export async function isAuthorizedGaurd(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const topLoadingStore = useTopLoadingStore()
  const user = userStore()
  try {
    await user.fetchUser()
    topLoadingStore.startLoading()
    topLoadingStore.updateProgress(80)
    next()
    topLoadingStore.updateProgress(100)
  } catch (e) {
    next('/')
  } finally {
    topLoadingStore.finishLoading()
  }
}

export async function userAccessGaurd(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const topLoadingStore = useTopLoadingStore()
  const user = userStore()
  try {
    switch (user.status) {
      case 'pub_new_onplad_created':
        next('/setup/email-verify')
        break
      case 'pub_new_onplad':
        next('/setup/company-profile')
        break
      case 'pub_company_profile_done':
        next('/setup/bank-details')
        break
      case 'pub_bank_details_done':
        next('/setup/documents')
        break
      case 'pub_under_verification':
        next('/setup/profile-verify')
        break
      case 'active':
        next('/onplad/publisher')
        break
      case 'inactive':
        next('/')
        break
      default:
        // user is inactive
        next('/')
    }
  } catch (e) {
    // something happened, system down
    next('/no-access')
  }
}

export async function userStatusGaurd(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const user = userStore()
  if (to.meta.statusNeeded === user.status) {
    next()
  } else {
    next('/app')
  }
}
